const EoPartnerAPI = (partnerId) => {
  if (!partnerId) {
    throw 'No partner ID specified.';
  }

  const BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/v1`;
  const PARTNER_URL = `${BASE_URL}/partner/${partnerId}`;

  return {
    getPartnerUrl: () => {
      return `${PARTNER_URL}`;
    },

    getSurveyQuestions: (token) => {
      return window.fetch(`${PARTNER_URL}/survey?token=${token}`).then((results) => {
        return results.json();
      });
    },

    getPartnerData: () => {
      return window.fetch(`${PARTNER_URL}/?type=portal`).then((results) => results.json());
    },

    getConsultDetails: (token) => {
      return window.fetch(`${PARTNER_URL}/consult?token=${token}`).then((result) => result.json());
    },

    getConsultToken: (type) => {
      return window.fetch(`${PARTNER_URL}/consult/token/${type}`).then((r) => r.json());
    },

    sendConsultRequest: async ({ info, availability, experts, autoPickedMap, caseDetails }) => {
      const formData = new FormData();

      // Info
      formData.append('adminCountryCode', info.adminCountryCode);
      formData.append('adminPhone', info.adminPhone);
      formData.append('countryCode', info.countryCode);
      formData.append('email', info.email);
      formData.append('phone', info.phone);

      // Case details
      formData.append('category', caseDetails.category);
      formData.append('country', caseDetails.country);
      formData.append('name', caseDetails.name);
      formData.append('repEmail', caseDetails.repEmail);

      // Multi
      caseDetails.multi.forEach((caseDetail, idx) => {
        const { ...relevantDetails } = caseDetail;

        // eslint-disable-next-line no-unused-expressions
        relevantDetails.formData
          ?.filter?.((el) => el.field.name === 'patientFiles')[0]
          ?.files?.forEach((file) => {
            formData.append(`medicalRecords_${idx}[]`, file);
          });

        formData.append('multi[]', JSON.stringify(relevantDetails));
      });

      // Experts
      experts.forEach((expert) => {
        formData.append('doctors[]', expert);
      });

      // autoPickedMap
      for (let expert in autoPickedMap) {
        if (autoPickedMap[expert]) {
          formData.append('autoPickedMap[]', expert);
        }
      }

      // Availability
      formData.append('nextDay', availability.nextDay);
      formData.append('reason', availability.reason);
      formData.append('recordMeeting', availability.recordMeeting);
      formData.append('records', availability.records);
      formData.append('timezone', JSON.stringify(availability.timezone));
      formData.append('voiceCall', availability.voiceCall);
      availability.availability.forEach((obj, idx) => {
        for (let property in obj) {
          formData.append(`availability_${idx}_${property}`, obj[property]);
        }
      });

      return window
        .fetch(`${PARTNER_URL}/consult`, {
          method: 'POST',
          body: formData
        })
        .then((r) => r.json());
    },

    sendConsultFeedback: (token, type, data) => {
      return window
        .fetch(`${PARTNER_URL}/consult/?token=${token}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            type,
            ...data
          })
        })
        .then((r) => r.json());
    },

    shareConsultPage: (data) => {
      return window
        .fetch(`${BASE_URL}/partner/share`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...data
          })
        })
        .then((r) => r.json());
    }
  };
};

export default EoPartnerAPI;
