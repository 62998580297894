import moment from 'moment-timezone';

const MONDAY = 1;
const SUNDAY = 0;
const SATURDAY = 6;
const FRIDAY = 5;
const SIX_PM_TIME = 18;

const STANDARD = 60;
const SHORT = 30;
const LONG = 90;

// todo: get this from the server instead.
const billingCodeLookup = {
  'glaukos-df2b0efe': 'GL',
  'bauschretina-cefd7c22': 'BR',
  'zeiss-87fda8': 'ZE',
  'bauschlomb-3dcd4f': 'BL',
  'corneagen-18fc4d': 'CG',
  'alcon-vision-2cf9f8': 'AV',
  'alcon-e5fb1f': 'AL',
  'jnj-071ddc1e': 'JS'
};

const billingValueLookup = {
  'Ab-A-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-A-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-A-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-B-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-B-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-B-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-C-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-C-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-C-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-D-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-D-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-D-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-E-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-E-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-E-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-F-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-F-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-F-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-G-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-G-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-G-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-H-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-H-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-H-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-I-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-I-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-I-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-J-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-J-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-J-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-K-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-K-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-K-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-L-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-L-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-L-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-M-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-M-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-M-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-N-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-N-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'Ab-N-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'AL-A-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'AL-A-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: '100'
  },
  'AL-A-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: '100'
  },
  'AL-B-0': {
    invoiceAmt: '1625',
    kolAmt: '845',
    lizAux: ''
  },
  'AL-B-1': {
    invoiceAmt: '1975',
    kolAmt: '995',
    lizAux: ''
  },
  'AL-B-2': {
    invoiceAmt: '2325',
    kolAmt: '1145',
    lizAux: ''
  },
  'AL-C-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'AL-C-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: '100'
  },
  'AL-C-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: '100'
  },
  'AL-D-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'AL-D-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: '100'
  },
  'AL-D-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: '100'
  },
  'AL-E-0': {
    invoiceAmt: '1625',
    kolAmt: '650',
    lizAux: '100'
  },
  'AL-E-1': {
    invoiceAmt: '1975',
    kolAmt: '800',
    lizAux: ''
  },
  'AL-E-2': {
    invoiceAmt: '2325',
    kolAmt: '950',
    lizAux: ''
  },
  'AL-F-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'AL-F-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: '100'
  },
  'AL-F-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: '100'
  },
  'AL-G-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'AL-G-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: '100'
  },
  'AL-G-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: '100'
  },
  'AL-H-0': {
    invoiceAmt: '1250',
    kolAmt: '650',
    lizAux: '100'
  },
  'AL-H-1': {
    invoiceAmt: '1600',
    kolAmt: '800',
    lizAux: ''
  },
  'AL-H-2': {
    invoiceAmt: '1950',
    kolAmt: '950',
    lizAux: ''
  },
  'AL-I-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'AL-I-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: '100'
  },
  'AL-I-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: '100'
  },
  'AL-J-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'AL-J-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: '100'
  },
  'AL-J-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: '100'
  },
  'AL-K-0': {
    invoiceAmt: '1625',
    kolAmt: '845',
    lizAux: ''
  },
  'AL-K-1': {
    invoiceAmt: '1975',
    kolAmt: '995',
    lizAux: ''
  },
  'AL-K-2': {
    invoiceAmt: '2325',
    kolAmt: '1145',
    lizAux: ''
  },
  'AL-L-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'AL-L-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: '100'
  },
  'AL-L-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: '100'
  },
  'AL-M-0': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: '100'
  },
  'AL-M-1': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: '100'
  },
  'AL-M-2': {
    invoiceAmt: '1050',
    kolAmt: '450',
    lizAux: '100'
  },
  'AL-N-0': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: '100'
  },
  'AL-N-1': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: '100'
  },
  'AL-N-2': {
    invoiceAmt: '1050',
    kolAmt: '450',
    lizAux: '100'
  },
  'AV-A-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-A-1': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-A-2': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-B-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-B-1': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-B-2': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-C-0': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-C-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-C-2': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-D-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-D-1': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-D-2': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-E-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-E-1': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-E-2': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-F-0': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-F-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-F-2': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-G-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-G-1': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-G-2': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-H-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-H-1': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-H-2': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-I-0': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-I-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-I-2': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-J-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-J-1': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-J-2': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-K-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-K-1': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-K-2': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-L-0': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-L-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-L-2': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'AV-M-0': {
    invoiceAmt: '0',
    kolAmt: '100',
    lizAux: ''
  },
  'AV-M-1': {
    invoiceAmt: '0',
    kolAmt: '100',
    lizAux: ''
  },
  'AV-M-2': {
    invoiceAmt: '0',
    kolAmt: '100',
    lizAux: ''
  },
  'AV-N-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-N-1': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'AV-N-2': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'BL-A-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'BL-A-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'BL-A-2': {
    invoiceAmt: '1',
    kolAmt: '650',
    lizAux: ''
  },
  'BL-B-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BL-B-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BL-B-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BL-C-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BL-C-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BL-C-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BL-D-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'BL-D-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'BL-D-2': {
    invoiceAmt: '1',
    kolAmt: '650',
    lizAux: ''
  },
  'BL-E-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BL-E-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BL-E-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BL-F-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BL-F-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BL-F-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BL-G-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'BL-G-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'BL-G-2': {
    invoiceAmt: '1',
    kolAmt: '650',
    lizAux: ''
  },
  'BL-H-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BL-H-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BL-H-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BL-I-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BL-I-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BL-I-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BL-J-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'BL-J-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'BL-J-2': {
    invoiceAmt: '1',
    kolAmt: '650',
    lizAux: ''
  },
  'BL-K-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BL-K-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BL-K-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BL-L-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BL-L-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BL-L-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BL-M-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'BL-M-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'BL-M-2': {
    invoiceAmt: '1',
    kolAmt: '650',
    lizAux: ''
  },
  'BL-N-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'BL-N-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'BL-N-2': {
    invoiceAmt: '1',
    kolAmt: '650',
    lizAux: ''
  },
  'BR-A-0': {
    invoiceAmt: '1',
    kolAmt: '400',
    lizAux: ''
  },
  'BR-A-1': {
    invoiceAmt: '1',
    kolAmt: '550',
    lizAux: ''
  },
  'BR-A-2': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BR-B-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BR-B-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BR-B-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BR-C-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BR-C-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BR-C-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BR-D-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'BR-D-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'BR-D-2': {
    invoiceAmt: '1',
    kolAmt: '650',
    lizAux: ''
  },
  'BR-E-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BR-E-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BR-E-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BR-F-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BR-F-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BR-F-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BR-G-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'BR-G-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'BR-G-2': {
    invoiceAmt: '1',
    kolAmt: '650',
    lizAux: ''
  },
  'BR-H-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BR-H-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BR-H-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BR-I-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BR-I-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BR-I-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BR-J-0': {
    invoiceAmt: '1',
    kolAmt: '350',
    lizAux: ''
  },
  'BR-J-1': {
    invoiceAmt: '1',
    kolAmt: '500',
    lizAux: ''
  },
  'BR-J-2': {
    invoiceAmt: '1',
    kolAmt: '650',
    lizAux: ''
  },
  'BR-K-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BR-K-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BR-K-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BR-L-0': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BR-L-1': {
    invoiceAmt: '1',
    kolAmt: '850',
    lizAux: ''
  },
  'BR-L-2': {
    invoiceAmt: '1',
    kolAmt: '1000',
    lizAux: ''
  },
  'BR-M-0': {
    invoiceAmt: '1',
    kolAmt: '400',
    lizAux: ''
  },
  'BR-M-1': {
    invoiceAmt: '1',
    kolAmt: '550',
    lizAux: ''
  },
  'BR-M-2': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'BR-N-0': {
    invoiceAmt: '1',
    kolAmt: '400',
    lizAux: ''
  },
  'BR-N-1': {
    invoiceAmt: '1',
    kolAmt: '550',
    lizAux: ''
  },
  'BR-N-2': {
    invoiceAmt: '1',
    kolAmt: '700',
    lizAux: ''
  },
  'GL-A-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'GL-A-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'GL-A-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'GL-B-0': {
    invoiceAmt: '1625',
    kolAmt: '845',
    lizAux: ''
  },
  'GL-B-1': {
    invoiceAmt: '1975',
    kolAmt: '995',
    lizAux: ''
  },
  'GL-B-2': {
    invoiceAmt: '2325',
    kolAmt: '1145',
    lizAux: ''
  },
  'GL-C-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'GL-C-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'GL-C-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'GL-D-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'GL-D-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'GL-D-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'GL-E-0': {
    invoiceAmt: '1625',
    kolAmt: '650',
    lizAux: ''
  },
  'GL-E-1': {
    invoiceAmt: '1975',
    kolAmt: '800',
    lizAux: ''
  },
  'GL-E-2': {
    invoiceAmt: '2325',
    kolAmt: '950',
    lizAux: ''
  },
  'GL-F-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'GL-F-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'GL-F-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'GL-G-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'GL-G-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'GL-G-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'GL-H-0': {
    invoiceAmt: '1250',
    kolAmt: '650',
    lizAux: ''
  },
  'GL-H-1': {
    invoiceAmt: '1600',
    kolAmt: '800',
    lizAux: ''
  },
  'GL-H-2': {
    invoiceAmt: '1950',
    kolAmt: '950',
    lizAux: ''
  },
  'GL-I-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'GL-I-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'GL-I-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'GL-J-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'GL-J-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'GL-J-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'GL-K-0': {
    invoiceAmt: '1625',
    kolAmt: '845',
    lizAux: ''
  },
  'GL-K-1': {
    invoiceAmt: '1975',
    kolAmt: '995',
    lizAux: ''
  },
  'GL-K-2': {
    invoiceAmt: '2325',
    kolAmt: '1145',
    lizAux: ''
  },
  'GL-L-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'GL-L-1': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'GL-L-2': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'GL-M-0': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'GL-M-1': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'GL-M-2': {
    invoiceAmt: '1050',
    kolAmt: '450',
    lizAux: ''
  },
  'GL-N-0': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'GL-N-1': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'GL-N-2': {
    invoiceAmt: '1050',
    kolAmt: '450',
    lizAux: ''
  },
  'JS-A-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-A-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-A-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-B-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-B-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-B-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-C-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-C-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-C-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-D-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-D-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-D-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-E-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-E-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-E-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-F-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-F-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-F-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-G-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-G-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-G-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-H-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-H-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-H-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-I-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-I-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-I-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-J-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-J-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-J-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-K-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-K-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-K-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-L-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-L-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-L-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-M-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-M-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-M-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-N-0': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-N-1': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'JS-N-2': {
    invoiceAmt: '0',
    kolAmt: '0',
    lizAux: ''
  },
  'ZE-A-0': {
    invoiceAmt: '700',
    kolAmt: '325',
    lizAux: ''
  },
  'ZE-A-1': {
    invoiceAmt: '1050',
    kolAmt: '475',
    lizAux: ''
  },
  'ZE-A-2': {
    invoiceAmt: '1400',
    kolAmt: '625',
    lizAux: ''
  },
  'ZE-B-0': {
    invoiceAmt: '1250',
    kolAmt: '650',
    lizAux: ''
  },
  'ZE-B-1': {
    invoiceAmt: '1600',
    kolAmt: '800',
    lizAux: ''
  },
  'ZE-B-2': {
    invoiceAmt: '1950',
    kolAmt: '950',
    lizAux: ''
  },
  'ZE-C-0': {
    invoiceAmt: '1625',
    kolAmt: '900',
    lizAux: ''
  },
  'ZE-C-1': {
    invoiceAmt: '1975',
    kolAmt: '1050',
    lizAux: ''
  },
  'ZE-C-2': {
    invoiceAmt: '2325',
    kolAmt: '1200',
    lizAux: ''
  },
  'ZE-D-0': {
    invoiceAmt: '700',
    kolAmt: '325',
    lizAux: ''
  },
  'ZE-D-1': {
    invoiceAmt: '1050',
    kolAmt: '475',
    lizAux: ''
  },
  'ZE-D-2': {
    invoiceAmt: '1400',
    kolAmt: '625',
    lizAux: ''
  },
  'ZE-E-0': {
    invoiceAmt: '1250',
    kolAmt: '650',
    lizAux: ''
  },
  'ZE-E-1': {
    invoiceAmt: '1600',
    kolAmt: '800',
    lizAux: ''
  },
  'ZE-E-2': {
    invoiceAmt: '1950',
    kolAmt: '950',
    lizAux: ''
  },
  'ZE-F-0': {
    invoiceAmt: '1625',
    kolAmt: '900',
    lizAux: ''
  },
  'ZE-F-1': {
    invoiceAmt: '1975',
    kolAmt: '1050',
    lizAux: ''
  },
  'ZE-F-2': {
    invoiceAmt: '2325',
    kolAmt: '1200',
    lizAux: ''
  },
  'ZE-G-0': {
    invoiceAmt: '700',
    kolAmt: '325',
    lizAux: ''
  },
  'ZE-G-1': {
    invoiceAmt: '1050',
    kolAmt: '475',
    lizAux: ''
  },
  'ZE-G-2': {
    invoiceAmt: '1400',
    kolAmt: '625',
    lizAux: ''
  },
  'ZE-H-0': {
    invoiceAmt: '1250',
    kolAmt: '650',
    lizAux: ''
  },
  'ZE-H-1': {
    invoiceAmt: '1600',
    kolAmt: '800',
    lizAux: ''
  },
  'ZE-H-2': {
    invoiceAmt: '1950',
    kolAmt: '950',
    lizAux: ''
  },
  'ZE-I-0': {
    invoiceAmt: '1625',
    kolAmt: '900',
    lizAux: ''
  },
  'ZE-I-1': {
    invoiceAmt: '1975',
    kolAmt: '1050',
    lizAux: ''
  },
  'ZE-I-2': {
    invoiceAmt: '2325',
    kolAmt: '1200',
    lizAux: ''
  },
  'ZE-J-0': {
    invoiceAmt: '770',
    kolAmt: '325',
    lizAux: ''
  },
  'ZE-J-1': {
    invoiceAmt: '1120',
    kolAmt: '475',
    lizAux: ''
  },
  'ZE-J-2': {
    invoiceAmt: '1470',
    kolAmt: '625',
    lizAux: ''
  },
  'ZE-K-0': {
    invoiceAmt: '1375',
    kolAmt: '650',
    lizAux: ''
  },
  'ZE-K-1': {
    invoiceAmt: '1725',
    kolAmt: '800',
    lizAux: ''
  },
  'ZE-K-2': {
    invoiceAmt: '2075',
    kolAmt: '950',
    lizAux: ''
  },
  'ZE-L-0': {
    invoiceAmt: '1787.5',
    kolAmt: '900',
    lizAux: ''
  },
  'ZE-L-1': {
    invoiceAmt: '2137.5',
    kolAmt: '1050',
    lizAux: ''
  },
  'ZE-L-2': {
    invoiceAmt: '2487.5',
    kolAmt: '1200',
    lizAux: ''
  },
  'ZE-M-0': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'ZE-M-1': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'ZE-M-2': {
    invoiceAmt: '1050',
    kolAmt: '450',
    lizAux: ''
  },
  'ZE-N-0': {
    invoiceAmt: '350',
    kolAmt: '150',
    lizAux: ''
  },
  'ZE-N-1': {
    invoiceAmt: '700',
    kolAmt: '300',
    lizAux: ''
  },
  'ZE-N-2': {
    invoiceAmt: '1050',
    kolAmt: '450'
  },
  '': {}
};

const consultCodeLookup = function (
  duration,
  isNoShow,
  isCancelled,
  isWeekend,
  isNextDayRequest,
  isSameDayRequest
) {
  if (isNoShow) {
    return 'N';
  }

  if (isCancelled) {
    return 'M';
  }

  if (isSameDayRequest) {
    switch (duration) {
      case SHORT:
        return 'A';
      case LONG:
        return 'C';
      case STANDARD:
      default:
        return 'B';
    }
  }

  if (isNextDayRequest) {
    switch (duration) {
      case SHORT:
        return 'D';
      case LONG:
        return 'F';
      case STANDARD:
      default:
        return 'E';
    }
  }

  if (isWeekend) {
    switch (duration) {
      case SHORT:
        return 'J';
      case LONG:
        return 'L';
      case STANDARD:
      default:
        return 'K';
    }
  }

  // standard weekday consult
  switch (duration) {
    case SHORT:
      return 'G';
    case LONG:
      return 'I';
    case STANDARD:
    default:
      return 'H';
  }
};

const getDateInPacific = (date) => {
  if (!date) {
    return null;
  }

  return moment(new Date(date)).tz('America/Los_Angeles');
};

const isBillableConsult = (consult) => {
  if (consult.status === 'CLOSED_DUPLICATE') {
    return false;
  }

  if (consult.status === 'CLOSED_NO_SHOW' || consult.expertResponse.timestamp !== null) {
    return true;
  }

  return false;
};

const isAfterHoursRequest = (reqDate) => {
  if (reqDate.hour() >= SIX_PM_TIME) {
    return true;
  }

  return false;
};

const isWeekend = (dow) => {
  if (dow === SATURDAY || dow === SUNDAY) {
    return true;
  }

  return false;
};

const isMondayAfterWeekendRequest = (callDOW, reqDOW) => {
  //if the consult schedule date of the week is a monday
  //and the request date was friday, saturday or sunday, this is a urgent request

  if (callDOW === MONDAY && (reqDOW === FRIDAY || reqDOW === SATURDAY || reqDOW === SUNDAY)) {
    return true;
  }

  return false;
};

const isSameDayRequest = (callDate, requestDate) => {
  if (callDate.isSame(requestDate, 'day')) {
    return true;
  }

  return false;
};

const isNextDayRequest = (callDate, requestDate) => {
  if (moment(requestDate).add(1, 'd').isSame(callDate, 'day')) {
    return true;
  }

  return false;
};

const isUrgentRequest = (consult) => {
  const toReturn = {
    weekend: false,
    nextDay: false,
    sameDay: false
  };

  /*
    Determine the call-scheduled date in Pacific Timezone.
    Determine the MA Approval Date in Pacific Timezone.
    If there is no MA Approval Date, use the Consult Request Date but adjust it if it's after hours

  */

  const callDate = getDateInPacific(consult.callDetails.date);
  let requestDate = getDateInPacific(consult.approval.timestamp);

  if (!requestDate) {
    requestDate = getDateInPacific(consult.requestDetails.timestamp);

    if (isAfterHoursRequest(requestDate)) {
      // since the request is after hours, consider the consult the next day
      requestDate.add(1, 'd');
    }
  }

  /*
    If the call scheduled date is a weekend, it is an urgent request (billing)
    If the call scheduled date is monday, and the schedule request date is the previous 3 days (Friday, Saturday, Sunday), it is an urgent request
    If the call schedule date is the same date as the schedule request date, or one more than the schedule request date, it is an urgent request.

    Otherwise, it is not an urgent request.
  */

  const callDayOfWeek = callDate.day();
  const requestDayOfWeek = requestDate.day();

  if (isWeekend(callDayOfWeek)) {
    toReturn.weekend = true;
  }

  if (isMondayAfterWeekendRequest(callDayOfWeek, requestDayOfWeek)) {
    toReturn.nextDay = true;
  }

  if (isSameDayRequest(callDate, requestDate)) {
    toReturn.sameDay = true;
  }

  if (isNextDayRequest(callDate, requestDate)) {
    toReturn.nextDay = true;
  }

  return toReturn;
};

const isConsultNoShow = (consult) => {
  if (consult.status === 'CLOSED_NO_SHOW') {
    return true;
  }

  return false;
};

const isConsultCancelled = (consult) => {
  if (consult.status === 'CLOSED_CANCELLED') {
    return true;
  }

  return false;
};

const getNumberOfLastMinuteReschedules = (consult) => {
  if (!consult.callDetails.rescheduled) {
    return 0;
  }

  return consult.auditLog.reduce((count, item) => {
    if (item.action === 'Consult was rescheduled on short notice.') {
      count++;
    }
    return count;
  }, 0);
};

const getDurationOffset = (consult, durationQuestions) => {
  const questions = durationQuestions?.[consult.surveyId];

  if (!questions) {
    return STANDARD;
  }

  const answerIdx = parseInt(consult.expertResponse[questions.questionId], 10);

  if (isNaN(answerIdx)) {
    alert(`consult missing duration question. Consult Id ${consult.id}`);
    return STANDARD;
  }

  const question = questions.options[answerIdx].toLowerCase();

  if (question.indexOf('standard') !== -1) {
    return STANDARD;
  } else if (question.indexOf('shorter') !== -1) {
    return SHORT;
  } else if (question.indexOf('longer') !== -1) {
    return LONG;
  } else {
    alert(`Unexpected answer to a duration questions ${question}`);
    return STANDARD;
  }
};

export const getBillingCode = (consult, durationQuestions) => {
  if (isBillableConsult(consult) === false) {
    return {};
  }

  const durationCode = getDurationOffset(consult, durationQuestions);
  const isUrgent = isUrgentRequest(consult);
  const isNoShow = isConsultNoShow(consult);
  const isCancelled = isConsultCancelled(consult);
  const lastMinuteCount = getNumberOfLastMinuteReschedules(consult);

  const isLizAux = consult.callDetails.selectedExpert?.id === 'elizabeth-yeu-08fcb1';

  let billingCode = `${billingCodeLookup[consult.partnerId]}-${consultCodeLookup(
    durationCode,
    isNoShow,
    isCancelled,
    isUrgent.weekend,
    isUrgent.nextDay,
    isUrgent.sameDay
  )}-${lastMinuteCount}`;

  const value = billingValueLookup[billingCode];
  let doctorAmount = value?.kolAmt || '';

  if (isLizAux && value?.lizAux) {
    doctorAmount = parseInt(doctorAmount, 10);
    doctorAmount += parseInt(value.lizAux, 10);
  }

  return {
    billingCode,
    isUrgent,
    isNoShow,
    isCancelled,
    lastMinuteCount,
    invoiceAmt: value?.invoiceAmt || '',
    doctorAmount
  };
};
