import React, { useState, useEffect } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Link } from '../../../partials/Link';
import Button from '@material-ui/core/Button';

const styles = (theme) => ({
  root: {
    margin: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  body1: {
    fontSize: '1rem',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: '300',
    lineHeight: '1.5',
    letterSpacing: '0.00938em'
  },
  wrapper: {
    display: 'flex',
    gap: '8px'
  }
});

const URL_BASE = `${process.env.REACT_APP_API_BASE_URL}/v1/partner`;

const CallComplete = (props) => {
  const { classes, match } = props;

  const [state, setState] = useState({
    processing: true,
    isError: false,
    errorMessage: ''
  });

  const [noShow, setNoShow] = useState(false);

  const [token, setToken] = useState(null);

  useEffect(() => {
    if (match && match.params && match.params.token) {
      setToken(match.params.token);
    }
  }, [match]);

  const checkConsultStatus = () => {
    window.fetch(`${URL_BASE}/consult-token/${token}`).then(async (res) => {
      try {
        res = await res.json();

        if (res.status !== 'ok') {
          setState({ isError: true, errorMessage: res.status, processing: false });
        } else {
          setState({ isError: false, errorMessage: null, processing: false });
        }
      } catch (e) {
        console.error(e);
      }
    });
  };

  const completeConsult = (noShow) => {
    window
      .fetch(`${URL_BASE}/consult`, {
        method: 'POST',
        body: JSON.stringify({
          token,
          noShow
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(async (res) => {
        try {
          res = await res.json();
        } catch (error) {
          console.error(error);
        }

        if (res.status !== 'ok') {
          setState({ isError: true, errorMessage: res.status, processing: false });
        } else {
          setState({
            complete: true,
            partnerId: res.partnerId,
            sError: false,
            processing: false
          });

          if (noShow) {
            setNoShow(true);
          }
        }
      });
  };

  useEffect(() => {
    if (token) {
      checkConsultStatus();
    }
  }, [token]);

  const needsProcessing = !state.isError && !state.processing && !state.complete;

  return (
    <div className={classes.root}>
      {needsProcessing && (
        <Alert severity="success">
          <AlertTitle>Almost there!</AlertTitle>
          <Typography variant="body1">
            Please click the "Complete Consult" button below to mark it as complete.
          </Typography>
          <Typography variant="body1">
            If the doctor did not show up for the consult, please click the "No Show" button
            instead.
          </Typography>
          <Typography variant="body1">
            If you need help, contact{' '}
            <a href="mailto:care@expertopinion.md">care@expertopinion.md</a>.
          </Typography>

          <div className={classes.wrapper}>
            <Button
              onClick={() => completeConsult(false)}
              style={{ marginTop: '24px' }}
              color="primary"
              variant="contained"
            >
              Complete Consult
            </Button>

            <Button
              onClick={() => completeConsult(true)}
              style={{ marginTop: '24px' }}
              color="secondary"
              variant="contained"
            >
              No Show
            </Button>
          </div>
        </Alert>
      )}

      {state.isError && (
        <Alert severity="error">
          <AlertTitle>Could not complete your consult</AlertTitle>
          <div className={classes.body1}>
            Unfortunately, we weren't able to complete your consult.
            <br />
            <br />
            <strong>System Response:</strong> <i>{state.errorMessage}</i>
            <p>
              If you have additional questions, reach out to{' '}
              <a href="mailto:aidan@expertopinion.md">Aidan</a>.
            </p>
          </div>
        </Alert>
      )}
      {state.processing && (
        <div>
          Processing
          <div>
            <LinearProgress />
          </div>
        </div>
      )}
      {state.complete && !noShow && (
        <Alert severity="success">
          <AlertTitle>Consult Complete</AlertTitle>
          <Typography variant="body1">
            Thank you. Your consult has been marked as complete.
          </Typography>
          {token && state.partnerId && (
            <div>
              <Typography variant="body1">
                Fill out the post consult questionnaire below now, or do it later, a link to the
                questionnaire has been mailed to you.
              </Typography>
              <Link base={'site'} path={`/${state.partnerId}/form/${token}`}>
                <Button style={{ marginTop: '24px' }} color="secondary" variant="contained">
                  Complete Consult Questionnaire
                </Button>
              </Link>
            </div>
          )}
        </Alert>
      )}

      {state.complete && noShow && (
        <Alert severity="info">
          <AlertTitle>Consult Marked as No Show</AlertTitle>
          <Typography variant="body1">
            The consult has been marked as a no show. No further action is required.
          </Typography>
          <Typography variant="body1">
            If you have any questions or need assistance, please contact{' '}
            <a href="mailto:care@expertopinion.md">care@expertopinion.md</a>.
          </Typography>
        </Alert>
      )}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(CallComplete);
